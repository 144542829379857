<template>
  <div class="unauthorized">
    <div class="xmark">
      <font-awesome-icon icon="xmark" />
    </div>
    <h1>Ops! Non hai il permesso <br>per accedere a questa sezione!</h1>
  </div>
</template>

<style lang="scss">
.unauthorized {
  display: flex;
  flex: 1;
  height: 70%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;

  h1 {
    font-size: 2em;
    font-weight: 400;
    line-height: 1.5em;
  }
  .xmark {
    margin: 0px 0px 20px 0px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: radial-gradient(circle at 50% 100%, #fcf4193a, #4fbeff56);
    height: 105px;
    width: 105px;
    border-radius: 50%;
    svg {
        height: 80px;
        width: 80px;

         path {
            fill:rgba(255, 255, 255, 1)!important;
          }
      }
  }
}
</style>
